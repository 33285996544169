<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { usePageStore } from '@voix/store/pageStore'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

defineProps({
  currentResort: {
    type: Object,
    default: () => {},
  },
  resorts: {
    type: Array,
    required: true,
  },
})

const pageStore = usePageStore()
const currentPage = pageStore.currentPage

const menuIsOpen = ref(false)
const scrollY = ref(0)
const hamburgler = ref(null)
const windowIsScrolled = computed(() => scrollY.value > 500)
const bookingWidgetStore = useBookingWidgetStore()
const showBookingWidget = computed(() => bookingWidgetStore.showBookingModal)

function toggleBookingModal() {
  bookingWidgetStore.toggleBookingModal('nav')
}

onMounted(() => {
  window.onscroll = () => {
    scrollY.value = window.scrollY
  }
})

const enLink = computed(() => {
  if (currentPage?.language_code === 'en')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find((localization: any) => localization.language_code === 'en')
})

const esLink = computed(() => {
  if (currentPage?.language_code === 'es')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find((localization: any) => localization.language_code === 'es')
})

const placeholderMenu = [
  'Rooms',
  'Activities',
  'Dining',
  'Spa',
  'Weddings',
  'Special Offers',

]
</script>

<template>
  <nav
    class="fixed top-0 left-0 w-full z-50 transition-all duration-300 ease-in"
    :class="{
      'scrolled': windowIsScrolled,
      'menu-open': menuIsOpen,
    }"
  >
    <div>
      <div class="font-sans grid grid-cols-12 xs:px-4 sm:py-2 md:py-4 md:px-0">
        <div class="hidden lg:col-span-2 flex items-center">
          <!-- <button class="px-4" @click="menuIsOpen = !menuIsOpen">
            <Icon ref="hamburgler" v-model="menuIsOpen" name="heroicons:bars-3" class="w-8 h-8 text-bonita-primary" />
          </button> -->
        </div>

        <div class="col-span-4 col-start-0 lg:col-span-4 lg:col-start-5 flex justify-center items-center relative right-0 lg:right-5 py-2 text-bonita-primary">
          <a href="/" class="block h-fit mx-auto text-white">
            <LogosBonita
              class="w-4/5 mx-auto"
              :class="{
                hidden: windowIsScrolled,
                block: !windowIsScrolled,
              }"
            />
            <LogosBonitaMedallion
              class="h-[70px] mx-auto"
              :class="{
                hidden: !windowIsScrolled,
                block: windowIsScrolled,
              }"
            />
          </a>
        </div>

        <div class="col-span-2 col-start-11 flex justify-end relative space-x-8 max-h-[104px] font-bonita-body text-white">
          <div
            class="flex flex-row justify-center items-center space-x-1"
            :class="{
              'text-bonita-inverse': windowIsScrolled,
              'text-white': !windowIsScrolled,
            }"
          >
            <a href="/">EN</a>
            <span class="font-bonita-primary">|</span>
            <a href="/es">ES</a>
          </div>

          <button
            class="text-bonita-inverse px-6 py-2 font-bonita-body uppercase"
            :class="{
              'bg-white text-bonita-primary my-3': windowIsScrolled,
              'bg-bonita-primary': !windowIsScrolled,
            }"
            @click="toggleBookingModal"
          >
            {{ $t("book-now") }}
          </button>
        </div>
      </div>

      <div
        class="fixed inset-0 bg-white text-bonita-primary duration-500"
        :class="{
          '-translate-x-full': !menuIsOpen,
        }"
      >
        <div class="font-sans grid grid-cols-12 py-4 xs:px-4 md:py-5 md:px-0 xl:py-7">
          <div class="col-span-2  flex items-center">
            <button class="px-4" @click="menuIsOpen = !menuIsOpen">
              <Icon name="heroicons:x-mark-20-solid" class="w-10 h-10 text-bonita-primary" />
            </button>
          </div>

          <div class="col-span-4 col-start-5 flex justify-start items-center relative right-5 sm:right-0 text-bonita-primary">
            <a href="/" class="block h-fit">
              <LogosBonita class="w-full" />
            </a>
          </div>

          <div class="col-span-2 col-start-11 flex justify-end relative text-bonita-primary">
            <button class="bg-bonita-primary text-bonita-inverse px-6 py-2 font-bonita-body uppercase" @click="toggleBookingModal">
              {{ $t("book-now") }}
            </button>
          </div>
        </div>

        <div class="absolute top-24 right-0 flex items-center justify-end space-x-6 pr-2 md:pr-0">
          <select
            class="appearance-none bg-transparent border-none text-bonita-primary text-3xl font-bonita-title pr-12 focus:border-transparent focus:outline-none"
          >
            <option :value="enLink?.path">
              En
            </option>
            <option :value="esLink?.path">
              Es
            </option>
          </select>
        </div>

        <ul class="pl-8">
          <li v-for="menuItem, key in placeholderMenu" :key="key">
            <a href="#" class="block py-3 text-3xl font-bonita-title">{{ menuItem }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="showBookingWidget" class="px-6 py-4 w-full relative"
    >
      <FlightBookingForm class="lg:container" />
      <button class="p-4 absolute z-100 top-1 lg:top-0 -right-1 m-4 lg:m-0 pointer-events-auto" @click="toggleBookingModal">
        <Icon name="heroicons:x-mark-20-solid" class="text-gray lg:text-bonita-primary w-8 h-8" />
      </button>
    </div>
  </nav>
</template>

<style scoped>
:global(body:has(.menu-open)) {
  overflow: hidden;
}

nav {
  @apply fixed top-0 left-0 right-0 z-50 transition-all duration-300 ease-in;

  .x-container {
    @apply flex flex-col h-full;
  }

  &.scrolled {
    @apply bg-bonita-primary shadow-xl text-white;
  }
}
</style>
